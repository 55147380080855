@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190);
  background-clip: padding-box; 
  border-right: 4px white solid;
  border-top: 4px white solid;
}

::-webkit-scrollbar-thumb:hover{
  background: rgb(181, 179, 179);
}

.founder {
  background: #f5f4f2 url('./assets/row_bg.png') !important;
  background-position: 0 0 !important;
  background-repeat: repeat !important;
}

.border_d{
  border-bottom: 1px solid rgba(211, 207, 207, 0.5);
}

.border_d:last-of-type {
  border: none;
}

.pit_img{
  opacity: 0.75;
}
.pit_img:hover{
  opacity: 0.4;
}

.pit_img:hover > .arrow_bg{
  background: white;
  color: black;
}